<template>  
      <div> 
        <div v-if="!showByScoreDist">
           <CRow > 
              <CCol md="12">
                      <div style="text-align:center ">
                          <div class="mb-2">
                              <CBadge class="bg-color1 text-white mr-1">高風險區</CBadge> 
                              <CBadge class="bg-color2 text-white mr-1">小心翼翼區</CBadge> 
                              <CBadge class="bg-color3 text-white mr-1">安全區</CBadge> 
                          </div>   
                          <vue-speedometer 
                              :needleHeightRatio="0.7"
                              :maxSegmentLabels="3"
                              :segments="3"
                              :customSegmentStops="[0, 30, 70, 100]"
                              :segmentColors='["#ff6384", "#ffce56", "#4bc0c0"]'
                              :value="totalscore"
                              :valueTextFontSize="'30px'"
                              :currentValueText='"${value}"'
                              :minValue="0"
                              :maxValue="100" 
                              :height="180"
                              :width="280"
                              :paddingVertical="10" 
                          />  
                      </div>   
              </CCol>   
           </CRow>   
          <CRow>
              <CCol md="12">  
                <div class="text-center" style="margin-top: 5px;">
                    <span v-if="totalscore < 30"><CIcon name="cil-frown" :height="50"  /><br><small>高風險區 </small></span>
                    <span v-else-if="totalscore >=30 && totalscore <70"><CIcon name="cil-meh"  :height="50" /><br><small>小心翼翼區 </small></span> 
                    <span v-else><CIcon name="cil-smile" :height="50" /><br><small>安全區</small> </span> 
                </div>  
            </CCol>
          </CRow>   
        </div> 
       <div v-else> 
        <CRow> 
         <CCol md="12">
          <div style="text-align:center "> 
             <v-chart ref="g_echart" :option="gauge_chart.option" autoresize style="width: 100%;height:13rem;"/>  
            <div class="DivInline">
              <div class="text-success"  >{{adl2Naml}} 推薦指數分布 </div>
                <div class="m-0">
                  <svg-img-icons v-c-tooltip="'<div class=text-left>該點所在縣市內，隨機抽取中高人口密集度的地點，於同零售產業中所得之分數段比例分布圖供參考。</div>'" height="15" name="question" width="16"/>
                </div>
            </div>
            <div class="d-flex justify-content-center">  
                <apexchart width="85%"  :height="bar_chart.height" style="right: 7px;position: relative;" :options="bar_chart.option" :series="bar_chart.series"></apexchart>  
            </div> 
          </div>   
         </CCol>   
      </CRow>   
       </div>  
     </div>     
</template>


<script>
    import VueSpeedometer from "vue-speedometer"
    import VueApexCharts from 'vue-apexcharts' 
 
    export default {
        name: 'gaugechart',
        components: { 
          VueSpeedometer , 
           'apexchart': VueApexCharts
        }, 
        data(){
            return {  
               totalscore:0,

               showByScoreDist:true,
               adl2Naml:undefined, 
               gauge_chart:{
                  option:undefined,
               },
               bar_chart:{
                 option:{},
                 series: undefined,
                 height:130
               },
               
            }
        },
        created() {
           this.initBarChart(0,undefined);
        },
        methods:{
            updateData(score,scoreDistribution,adl5Info) { 
              
               this.totalscore = parseInt(score); 
               this.showByScoreDist = scoreDistribution !== undefined; 
               
               if(this.showByScoreDist){ 
                  this.initGaugeChart(this.totalscore,scoreDistribution);
                  this.initBarChart(this.totalscore,scoreDistribution); 
                  this.adl2Naml = adl5Info.adl2_namel;  
               } 
            },
            initBarChart(score,scoreDistribution){
               
               let _categories =[];
               let _data =[];
               let _color =[];  

               if(scoreDistribution!==undefined){
                  for (const [key, value] of Object.entries(scoreDistribution.ScoreDistributionsRatio)) {

                                        _categories.push(key); 
                                        _data.push((value*100).toFixed(0)); 
                                        
                                        const range = key.split("-");
                                        let min = parseInt(range[0].trim());
                                        let max = parseInt(range[1].trim());

                                        if(score >= min && score <= max) { 
                                          _color.push("#6c6e70"); 
                                        }
                                        else{
                                          _color.push("#d4d5d4"); 
                                        }

                  } 
               } 
 
               
               let bar_chart_option=  { 
                    colors : ['#A2C47A'], 
                    chart: { 
                        type:'bar', 
                        zoom: {
                            enabled: false
                        },
                       toolbar: {
                            show: false
                        } 
                    },
                    xaxis: { 
                        type: 'category',
                        categories:_categories ,
                         labels: {
                        show: true,
                        style: { 
                            fontFamily: '微軟正黑體', 
                        },
                      }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                        show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val) {
                                return val + "%";
                            }, 
                        } 
                    },  
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top',
                            },
                            columnWidth: '55%', 
                            distributed: true,
                        },
                      
                    },
                  colors: _color,
                  legend: {
                           show: false
                          },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                          return val + "%";
                        },
                         offsetY: -20,
                        style: {
                            fontSize: '11px',
                            colors: ["#000000"],
                            fontFamily:'微軟正黑體'
                        }, 
                    },  
                    grid: {
                      borderColor: '#ffffff', 
                    },
                    tooltip: {
                        enabled: false,
                    },
                    states: {
                        hover: {
                        filter: {
                            type: 'none'
                          }
                        }
                    } 
               };
               
               let bar_chart_series =  [{ name:'分數分佈', data: _data }];
               
               this.bar_chart.option = bar_chart_option;
               this.bar_chart.series = bar_chart_series; 
               
            },
            initGaugeChart(score,scoreDistribution){ 

                  let _gaugeColor = ['#94db57','#84af60', '#3f681c'];   
              
                  const _axisLineColorOption =[];
                  (scoreDistribution.ScoreSegment).concat(1).map(function (value, index, array){
                      _axisLineColorOption.push( [value,_gaugeColor[index]]);
                  }); 

 
                  let _option ={
                          grid:{
                                    containLabel: true,
                                    left: 0,
                                    top: 0,
                                    right: 0,
                                    bottom: 0
                          },
                          series: [
                          {
                                  type: 'gauge',
                                  startAngle: 180,
                                  endAngle: 0, 
                                  min: 0,
                                  max: 100, 
                                  radius: '135%',  
                                  center: ['50%', '80%'], 
                                  axisLine: {
                                    lineStyle: {
                                      color: [[1, '#f1efef']], //ebebeb
                                      width: 20
                                    }
                                  },
                                  splitLine: {
                                    distance: -18,
                                    length: 10,
                                    lineStyle: {
                                      color: '#000'
                                    }
                                  },
                                  axisTick: { 
                                    distance: -18,
                                    length: 5,
                                    lineStyle: {
                                      color: '#000'
                                    }
                                  },
                                  axisLabel: {
                                    distance: -10,
                                    color: '#000',
                                    fontSize: 11, 
                                  },
                                  anchor: {
                                    show: false, 
                                  }, 
                                  title: {
                                  show:false
                                  }, 
                          },
                          {
                                type: 'gauge',
                                startAngle: 180,
                                endAngle: 0, 
                                min: 0,
                                max: 100,
                                  radius: '120%', 
                                    center: ['50%', '80%'], 
                                  itemStyle: {
                                  color: '#FFAB91'
                                }, 
                                  axisLine: {
                                  lineStyle: {
                                    width: 80,
                                    color: _axisLineColorOption
                                  }
                                },
                                  axisTick: {
                                  show:false 
                                }, 
                                splitLine: { 
                                  distance: -0,
                                  length: 0, 
                                  lineStyle: {
                                    width: 0,
                                    color: '#fff'
                                  }
                                },
                                axisLabel: {
                                  show:false 
                                }  ,
                                pointer: {
                                  itemStyle: {
                                    color: '#000'
                                  }, 
                                  length: 110,
                                  offsetCenter: [0, '-30%']
                                },
                                detail: {
                                  valueAnimation: true, 
                                  offsetCenter: ['5%', '-5%'],
                                  fontSize: 40,
                                  fontWeight: 'bolder',
                                  formatter: '{value} ',
                                  color: '#EE8C33', // 'inherit',
                                  padding: 0 ,
                                },
                                data: [
                                  {
                                    value: score, 
                                  }
                                ]
                          },  
                          ]
                        };  
                  this.gauge_chart.option =_option;
                  this.$refs['g_echart'].setOption(this.gauge_chart.option);
            }
           // setTotalScore(score){ 
           //     this.totalscore = parseInt(score); 
           // }
        },  
    }
</script>