<template> 
    <apexchart :height="chartheight" :options="chartOptions" :series="series"></apexchart>  
</template> 

<script>  
import VueApexCharts from 'vue-apexcharts'

let _options =  {
    
    colors : ['#A2C47A'], 
    chart: {
        id: 'ageBarChart',
        type:'bar', 
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        } 
    },
    xaxis: { 
        type: 'category',
        categories: ['0-14歲','15-24歲','25-34歲','35-44歲','45-54歲','55-64歲','65歲以上' ], 
        labels: {
          show: true,
          style: { 
              fontFamily: '微軟正黑體', 
          },
        }
    },
      yaxis: {
        axisBorder: {
            show: false
        },
        axisTicks: {
         show: false,
        },
        labels: {
            show: false,
            formatter: function (val) {
                return val + "%";
            },
            colors : ['#1A5611']
        }
    
    }, 
    plotOptions: {
        bar: {
            dataLabels: {
                position: 'top',
            },
           // columnWidth: '40%',
        },
        
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
            fontSize: '10px',
            colors: ["#1A5611"],
            fontFamily:'微軟正黑體'
        }
    },  
    grid: {
          borderColor: '#ffffff',
          row: {
            colors: ['#f2f2f2', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
    },

};  
let _series = [{
    name: '',
    data: [0,0,0,0,0,0,0]
}];

export default {
    name:'ageChart',
    components: {
       'apexchart': VueApexCharts,
    },
    data: function() {
    return {
        chartOptions: _options,
        series:_series, 
        chartheight:"200" 
      }
    }, 
    methods: {
        updateChart(ageArrayData) { 
            this.series = [{ 
                data: ageArrayData 
            }]
        }
    }, 

} 
</script> 