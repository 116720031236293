<template>
<div>
    <CRow> 
        <CCol>
            <CWidgetBrand  color="greela" class="cardborder-g">
            <CIcon    name="cil-people"  height="30"  class="my-4"/>
            <div class="DivInline" >
                    <div><h5 class="mr-1">基本人口</h5></div>  
                    <div><svg-img-icons name="questionW" width="22" height="20" v-c-tooltip="'<div class=text-left>該區每日長時間停留人口及當地住戶人口</div>'"/></div>   
            </div> 
            <template slot="body">
                <div class="card-body row text-center"> 
                    <div class="col">
                        <div class="text-value-lg">  {{res_ttl}} </div>
                        <div class="text-uppercase text-muted small"> 居住人口 </div>
                    </div>
                    <div class="c-vr"></div>
                    <div class="col">
                        <div class="text-value-lg"> {{wrk_ttl}} </div>
                        <div class="text-uppercase text-muted small"> 工作人口 </div>
                    </div>
                    <div class="c-vr"></div>
                    <div class="col">
                        <div class="text-value-lg"> {{hh_ttl}} </div>
                        <div class="text-uppercase text-muted small"> 家戶數 </div>
                    </div>
                 
                </div>
            </template>
            </CWidgetBrand>
        </CCol> 
    </CRow>
       <br> 
      <CRow> 
        <CCol>
            <CWidgetBrand  color="greela" class="cardborder-g" >
            <CIcon  name="cil-people"  height="30"  class="my-4"/>
            <div class="DivInline" >
                    <div><h5 class="mr-1">附近學生數(人)</h5></div>  
                    <div><svg-img-icons name="questionW" width="22" height="20" v-c-tooltip="'此服務範圍內所在學校之學生人數'"/></div>    
            </div> 
            <template slot="body">
                 <div class="card-body row text-center"> 
                    <div class="col">
                        <div class="text-value-lg">  {{primarySchool_ttl}} </div>
                        <div class="text-uppercase text-muted small"> 國中及以下 </div>
                    </div>
                    <div class="c-vr"></div>
                    <div class="col">
                        <div class="text-value-lg"> {{highSchool_ttl}} </div>
                        <div class="text-uppercase text-muted small"> 高中(職) </div>
                    </div>
                    <div class="c-vr"></div>
                    <div class="col">
                        <div class="text-value-lg"> {{university_ttl}} </div>
                        <div class="text-uppercase text-muted small"> 大專院校 </div>
                    </div>
                    <br>
                </div>
            </template>
          
            </CWidgetBrand>
        </CCol>
        <br><br> <br> 
    </CRow>
    
</div>
</template>


<script>
 import ageBarChart from '../components/_MarketReportResult_ageChart.vue';     export default {
        name: 'consumersection',
          components: { 
            
        },
         data(){
            return {
                avgfootfall:"",
                res_ttl:"",
                wrk_ttl:"",
                hh_ttl:"",
                primarySchool_ttl:"", 
                highSchool_ttl:"",
                university_ttl:""
            }
        },
        methods:{
            setData(data){
                //以下資料皆必須為字串型態
                this.avgfootfall = data.avgfootfall; 
                this.res_ttl= data.res_ttl;  
                this.wrk_ttl=data.wrk_ttl;
                this.hh_ttl=data.hh_ttl;
                this.primarySchool_ttl=data.primarySchool_ttl, 
                this.highSchool_ttl=data.highSchool_ttl,
                this.university_ttl=data.university_ttl
            }
        }
    }
</script>