var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"m-0 p-0"},[_c('CCol',{staticClass:"m-0 p-1 pr-1 col-12 col-md-4"},[_c('div',{staticClass:"text-center card"},[_c('CRow',{staticClass:"m-0"},[_c('CCol',{staticClass:"mt-2 mr-0 pr-0"},[_c('span',[_c('svg-img-icons',{attrs:{"height":"32","name":"metro","width":""}})],1),_c('span',{staticClass:"text-muted text-uppercase font-weight-bold small ml-1"},[_vm._v("捷運")])]),_c('CCol',{staticClass:"mt-2"},[_c('span',{staticClass:"text-value-xl"},[_vm._v(" "+_vm._s(_vm.trafficInfo.Mrt.Count)+" ")])])],1),_c('CRow',{staticClass:"mt-1 mb-1"},[_c('CCol',{},[_c('table',{staticClass:"table p-0 m-0",staticStyle:{"height":"10vh"}},[(
                _vm.trafficInfo.Mrt.TransportFootfallInfo !== null &&
                _vm.trafficInfo.Mrt.TransportFootfallInfo.length > 0
              )?[_vm._l((_vm.trafficInfo.Mrt.TransportFootfallInfo),function(item,index){return [(item.Out > 0)?_c('tr',{key:item.Name},[_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"align":"center","width":"40%"}},[_c('span',{staticClass:"text-uppercase small badge badge-success text-white"},[_vm._v(_vm._s(item.Name))])]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text-value-md"},[_vm._v(_vm._s(_vm.numberWithCommas(item.Out))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.unit)}})])])]):_vm._e()]}),(
                  _vm.trafficInfo.Mrt.TransportFootfallInfo.filter(
                    function (x) { return x.Out === 0; }
                  ).length > 0
                )?[_c('tr',[_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"align":"center","width":"40%"}},_vm._l((_vm.trafficInfo.Mrt
                        .TransportFootfallInfo),function(item,index){return (item.Out === 0)?_c('span',{staticClass:"text-uppercase small badge badge-success text-white"},[_vm._v(_vm._s(item.Name))]):_vm._e()}),0),_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text-value-md"},[_vm._v("尚無統計資料")])])])]:_vm._e()]:[_c('tr',[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('div',[_vm._v("0 "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.unit)}})])])])]],2)])],1)],1)]),_c('CCol',{staticClass:"m-0 p-1 pr-1 col-12 col-md-4"},[_c('div',{staticClass:"text-center card"},[_c('CRow',{staticClass:"m-0"},[_c('CCol',{staticClass:"mt-2 mr-0 pr-0"},[_c('span',[_c('svg-img-icons',{attrs:{"height":"32","name":"taiwan_Railways","width":""}})],1),_c('span',{staticClass:"text-muted text-uppercase font-weight-bold small ml-1"},[_vm._v("台鐵")])]),_c('CCol',{staticClass:"mt-2"},[_c('span',{staticClass:"text-value-xl"},[_vm._v(" "+_vm._s(_vm.trafficInfo.Train.Count)+" ")])])],1),_c('CRow',{staticClass:"mt-1 mb-1"},[_c('CCol',{},[_c('table',{staticClass:"table p-0 m-0",staticStyle:{"height":"10vh"}},[(
                _vm.trafficInfo.Train.TransportFootfallInfo !== null &&
                _vm.trafficInfo.Train.TransportFootfallInfo.length > 0
              )?[_vm._l((_vm.trafficInfo.Train
                  .TransportFootfallInfo),function(item,index){return [(item.Out > 0)?_c('tr',{key:item.Name},[_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"align":"center","width":"40%"}},[_c('span',{staticClass:"text-uppercase small badge badge-info",staticStyle:{"background-color":"#093e90"}},[_vm._v(_vm._s(item.Name))])]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text-value-md"},[_vm._v(_vm._s(_vm.numberWithCommas(item.Out))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.unit)}})])])]):_vm._e()]})]:[_c('tr',[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('div',[_vm._v("0 "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.unit)}})])])])]],2)])],1)],1)]),_c('CCol',{staticClass:"m-0 p-1 pr-1 col-12 col-md-4"},[_c('div',{staticClass:"text-center card"},[_c('CRow',{staticClass:"m-0"},[_c('CCol',{staticClass:"mt-2 mr-0 pr-0"},[_c('span',[_c('svg-img-icons',{attrs:{"height":"32","name":"THSR","width":" "}})],1),_c('span',{staticClass:"text-muted text-uppercase font-weight-bold small ml-1"},[_vm._v("高鐵")])]),_c('CCol',{staticClass:"mt-2"},[_c('span',{staticClass:"text-value-xl"},[_vm._v(" "+_vm._s(_vm.trafficInfo.FastTrain.Count)+" ")])])],1),_c('CRow',{staticClass:"mt-1 mb-1"},[_c('CCol',{},[_c('table',{staticClass:"table p-0 m-0",staticStyle:{"height":"10vh"}},[(
                _vm.trafficInfo.FastTrain.TransportFootfallInfo !== null &&
                _vm.trafficInfo.FastTrain.TransportFootfallInfo.length > 0
              )?_vm._l((_vm.trafficInfo.FastTrain
                  .TransportFootfallInfo),function(item,index){return _c('tr',{key:item.Name},[_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"align":"center","width":"40%"}},[_c('span',{staticClass:"text-uppercase small badge badge-warning text-white",staticStyle:{"background-color":"#f2741b"}},[_vm._v(_vm._s(item.Name))])]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text-value-md"},[_vm._v(_vm._s(_vm.numberWithCommas(item.Out))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.unit)}})])])])}):[_c('tr',[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('div',[_vm._v("0 "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.unit)}})])])])]],2)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }