<template>
<div>
    <CRow> 
        <CCol >
            <CWidgetBrand
            color="greela" class="cardborder-g"
            :right-header="avg_invoices_serviceArea"
            right-footer="平均每月消費發票張數"
            :left-header="avg_amont_serviceArea"
            left-footer="平均單張消費金額"
            >
                <CIcon
            name="cil-cart"
                height="30"
            class="my-4" />
             <div class="DivInline" >
                 <div><h5>該區平均消費</h5> </div>
                 <div><svg-img-icons name="questionW" width="22" height="20" v-c-tooltip="'<div class=text-left>該位置周遭250公尺消費狀況​</div>'"/></div>   
             </div>
            </CWidgetBrand>
        </CCol>
       
    </CRow>
    <CRow>
         <CCol  >
            <CWidgetBrand
            color="greela" class="cardborder-g"
            :right-header="avg_invoices_all"
            right-footer="平均每月消費發票張數"
            :left-header="avg_amont_all"
            left-footer="平均單張消費金額" 
            >
            <CIcon
            name="cil-money"
            height="30"
            class="my-4" />
             <div class="DivInline" >
                 <div> <h5>全縣(市)平均消費</h5></div>
                 <div><svg-img-icons name="questionW" width="22" height="20" v-c-tooltip="'<div class=text-left>全縣市每250公尺半徑範圍內平均消費狀況​​</div>'"/></div>   
             </div> 
            </CWidgetBrand>
        </CCol>  
    </CRow>
</div>
</template>



<script> 
export default {
    name: 'consumptionsection',
        components: { 
          
    },
        data(){
        return {
            avg_invoices_serviceArea:"",
            avg_amont_serviceArea:"",
            avg_invoices_all:"",
            avg_amont_all:"",
        }
    },
    methods:{
        setData(data){
            this.avg_invoices_serviceArea=data.avg_invoices_serviceArea;
            this.avg_amont_serviceArea=data.avg_amont_serviceArea;
            this.avg_invoices_all=data.avg_invoices_all;
            this.avg_amont_all=data.avg_amont_all;
        }
    }
}
</script>