<template>
  <div>
    <CRow>
      <CCol>
        <div class="row b-block ">
          <div class=" col"><span style="font-size:1.1rem;color:#888;margi:0px">商圈屬性</span></div>
          <div class="b-block-cont col-8"><p><span> {{ businessAttribute }}</span></p></div>
        </div>
      </CCol>
    </CRow>
    <CRow >
      <CCol>   
           <polar-area-chart :chart-data="chartData" :options="chartOptions" ref="polarareachartref" style=" height:360px;display: block; margin: 0 auto;"></polar-area-chart>  
      </CCol>
    </CRow>
  </div>
</template>


<script>
import PolarAreaChart from '../components/DisplayTemplate/vue-chartjs/PolarAreaChart'

export default {
  name: 'ComprehensiveIndicators',
  components: {
    PolarAreaChart
  },
  data: function () {
    return {
      businessAttribute: "",
      chartData: {},
      chartOptions: {}
    }
  },
  methods: {
    setData(comprehensiveIndicatorsData) {
      if (comprehensiveIndicatorsData !== undefined) {
        var attributes = (comprehensiveIndicatorsData.Attributes === undefined) ? "" : comprehensiveIndicatorsData.Attributes.join();
        this.businessAttribute = attributes;
        this.updateChart(comprehensiveIndicatorsData);
      }


    },
    updateChart(data) {

      this.chartData = {
        labels: ['住宅', '商業', '旅遊', '文教', '休閒娛樂'],
        datasets: [
          {
 
            backgroundColor: [
              'rgba(136, 136, 136,0.6)',
              'rgba(251, 146, 36,0.6)',
              'rgba(237, 197, 49,0.6)',
              'rgba(137, 101, 0,0.6)',
              'rgba(100, 163, 111,0.6)'
            ],
            data: [
              data.Residential,
              data.Business,
              data.Tourism,
              data.Educational,
              data.Leisure
            ]
            /* [1, 2, 3, 4, 5]   */
          }
        ]
      }

      this.chartOptions = {
        responsive: true, 
        maintainAspectRatio: false,
        scale: {
          ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
            fontSize: 13, 
            fontColor: '#000'
          }
        },
        legend: {
           fontFamily:'微軟正黑體',
           fontSize: '14px', 
           color: '#dfsda',
           floating: false,  
           fontStyle: "bold", 
           labels: {
            usePointStyle: true,
           },
           position: 'bottom'
        }
      }
    }
  },
}
</script> 

 

