<template>
  <div>
    <CRow class="justify-content-center PoiChartDiv">
      <CCol class="PoiChartDiv">
        <div class="pull-right" v-if="ShowChartType">
          <label
            class="label-checkbox"
            v-for="ctype in chartTypeList"
            :key="ctype.value"
            :class="{ checked: ctype.value === chartType }"
            :for="ctype.value"
          >
            <input
              type="radio"
              v-model="chartType"
              name="chartType"
              :id="ctype.value"
              class="check-day"
              :value="ctype.value"
            />{{ ctype.label }}
          </label>
        </div>
      </CCol>
    </CRow>
    <CRow class="justify-content-center " :key="updateApexChart">
      <apexchart
        id="businessRegisterationChart"
        ref="businessRegisterationChart"
        type="donut"
        width="500"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </CRow>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

const DEF_CHART_TYPES = [
  { label: "全部", value: "All", color: ["#9CBE40", "#90AEE6", "#F6C857"] },
  {
    label: "餐飲",
    value: "餐飲",
    color: [
      "#99BA3B",
      "#96CEB4",
      "#546658",
      "#93C572",
      "#20BD4A",
      "#436B00",
      "#96D65E",
      "#5F8575",
      "#C9CC3F",
      "#2F6B00",
      "#8A9A5B",
      "#C1E1C1",
    ],
  },
  {
    label: "零售",
    value: "零售",
    color: [
      "#90AEE6",
      "#6E7180",
      "#E1E7E0",
      "#6AA4B0",
      "#2F6D80",
      "#B9D4CE",
      "#2187B0",
      "#A7AABC",
      "#163B50",
      "#C9D8D0",
      "#87A1B7",
      "#394955",
    ],
  },
  {
    label: "生活服務",
    value: "生活服務",
    color: [
      "#F6C857",
      "#96935D",
      "#676900",
      "#8FAF99",
      "#EB8074",
      "#C4B894",
      "#FFAD00",
      "#C9A48D",
      "#FFC6A3",
      "#AEAC9A",
      "#F5959C",
      "#AD9562",
    ],
  },
];

let _options = {
  labels:[],
  legend: {
    position: "right",
     horizontalAlign: "right",
    fontSize: "11px",
    fontFamily: "微軟正黑體" ,
    width:170,
  },
  theme: {
    mode: "light",
    palette: "palette3",
    monochrome: {
      enabled: false,
      shadeTo: "light",
      shadeIntensity: 0.65,
    },
  },
  responsive: [
    {
      breakpoint: 1025,
      options: {
        chart: {
          width:500,
          height: 420,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          floating: false,
          fontSize: "11px",
          fontFamily: "微軟正黑體",
          height:120,
          width:"100%"
        }
      },
    },
  ],
  plotOptions: {
   pie: {
   donut: {
        expandOnClick: true,
        size: "60%",
        labels: {
          show: true,
          name: {
            show: true,
            fontFamily: "微軟正黑體",
            fontSize: "14px",
            color: "#dfsda",
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: "24px",
            color: undefined,
            offsetY: 16,
            formatter: function(val) {
              return val;
            },
          },
          total: {
            show: true,
            label: "合計",
            color: "#373d3f",
            formatter: function(w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter(val, opts) {
      //const name = opts.w.globals.labels[opts.seriesIndex];
      return val.toFixed(1) + "%"; //[name, val.toFixed(1) + '%']
    },
  },
  tooltip: {
    enabled: false,
  },
};

let _series = [431, 321, 100];

export default {
  name: "BusinessRegistrationPoiChart",
  components: {
    apexchart: VueApexCharts,
  },
  data: function() {
    return {
      chartOptions: _options,
      series: _series,
      chartTypeList: DEF_CHART_TYPES,
      chartType: "All",
      ShowChartType: false,
      BusinessRegistrationPoi: null,
      BusinessRegistrationPoiPerClassType: null,
      updateApexChart:0
    };
  },
  methods: {
    updateChart(data, dataPerClassType, industry) {
      this.BusinessRegistrationPoi = data; //舊的json格式資料
      this.BusinessRegistrationPoiPerClassType = dataPerClassType; //新的json格式
      if (industry === undefined) {
        this.chartType = "餐飲";
        this.ShowChartType = false;
      } else {
        this.chartType = industry.ClassType;
        this.ShowChartType = true;
      }

      this.refreshChart();
    },
    refreshChart() {
      let poiData = null;
      if (this.BusinessRegistrationPoiPerClassType === undefined)
        poiData = this.BusinessRegistrationPoi;
      else {
        if (
          this.BusinessRegistrationPoiPerClassType[this.chartType] !== undefined
        )
          poiData = this.BusinessRegistrationPoiPerClassType[this.chartType];
      }
      let seriesdata = [];
      let labels = [];
      if (poiData !== null) {
        seriesdata = poiData.map((item) => item.Count);
        labels = poiData.map((item) => item.Name);
      }

      this.series = seriesdata;

      this.$nextTick(function() {
        this.chartOptions.labels = labels;
        this.chartOptions.colors = DEF_CHART_TYPES.find((x) => x.value == this.chartType).color;
        //this.chartOptions = {
        //  labels: labels,
        //  colors: DEF_CHART_TYPES.find((x) => x.value == this.chartType).color,
        //};

        //console.log(this.chartOptions);
        this.$refs.businessRegisterationChart.updateOptions(this.chartOptions);
        this.updateApexChart ++;
      });
    },
  },
  watch: {
    chartType: function(newValue) {
      this.refreshChart();
      this.$emit('updateParentPoiChartTitle',newValue);
    },
  },
};
</script>
