<script>
import { PolarArea,mixins } from 'vue-chartjs'

export default {
  extends: PolarArea,
  mixins: [mixins.reactiveProp],
  props: ['chartdata', 'options'],  
  mounted() {
    this.renderLineChart()
  },
  methods: {
    renderLineChart() {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>