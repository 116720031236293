<template>
<div>
    <CRow>  
        <CCol>
            <CWidgetBrand
            color="greela" class="cardborder-g"
            :right-header="male_female_ratio"
            right-footer="男女比例"
            :left-header="top_Age_groups"
            left-footer="15-64歲族群中，佔比最高的年齡族群"
            >
            <CIcon
            name="cil-wc"
            height="30"
            class="my-4"/>
             <div class="DivInline" >
                    <div><h5 class="mr-1">性別年齡</h5></div>  
                    <div><svg-img-icons name="questionW" width="22" height="20" v-c-tooltip="'<div class=text-left>此服務範圍內之居住人口男女比例及主要組成年齡</div>'"/></div>   
            </div>
            </CWidgetBrand>
        </CCol>  
    </CRow>
    <CRow>
        <CCol md="12">
            <ageBarChart ref="ageBarChart"/>
        </CCol> 
    </CRow>
</div>
</template>


<script>
 import ageBarChart from '../components/_MarketReportResult_ageChart.vue';
    export default {
        name: 'consumersection',
          components: { 
            ageBarChart, 
        },
         data(){
            return { 
               male_female_ratio:"",
               top_Age_groups:""
            }
        },
        methods:{
            setData(data, ageData){
                //以下資料皆必須為字串型態
             
                this.male_female_ratio= data.male_female_ratio;
                this.top_Age_groups=data.top_Age_groups;

                this.$refs.ageBarChart.updateChart(ageData);
            }
        }
    }
</script>